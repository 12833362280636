import React from "react";
import {Route, Switch} from 'react-router';
import asyncComponent from "./utils/AsyncComponent";
import {HashRouter} from "react-router-dom";
import TestProgress from "./pages/TestProgress";
import Play from "./pages/minhlam/play";
import Award from "./pages/minhlam/award";

// import async - code splitting
const AsyncDashboard = asyncComponent(() => import("./pages/Dashboard"));
const HueDashboard = asyncComponent(() => import("./pages/HueDashboard"));
const Authenme = asyncComponent(() => import("./pages/Authenme"));
const AsyncBoardDetail = asyncComponent(() => import("./pages/BoardDetail"));
const Async404 = asyncComponent(() => import("./pages/404"));
const AsyncProject = asyncComponent(() => import("./pages/requirements/Project"));
const AsyncFeature = asyncComponent(() => import("./pages/requirements/Feature"));
const AsyncTags = asyncComponent(() => import("./pages/requirements/Tags"));
const AsyncTodos = asyncComponent(() => import("./pages/todos/index.jsx"));
const AsyncJira = asyncComponent(() => import("./pages/jira/JiraApp"));
const AsyncJsonSchema = asyncComponent(() => import("./pages/tools/JsonSchema"));
export default (
    <HashRouter>
        <Switch>
            {/*<RequireLoginRoute exact path="/" component={AsyncDashboard}/>*/}
            <Route exact path="/hue/" component={HueDashboard}/>
            <Route exact path="/authenme/" component={Authenme}/>
            <Route exact path="/" component={AsyncDashboard}/>
            <Route exact path="/jira/board/:id" component={AsyncBoardDetail}/>
            <Route exact path="/requirements/:id" component={AsyncProject}/>
            <Route exact path="/requirements/:id/features/:index" component={AsyncFeature}/>
            <Route exact path="/requirements/:id/tags/:tag" component={AsyncTags} RouteKey={true}/>
            <Route path="/jira" component={AsyncJira}/>
            <Route path="/todos" component={AsyncTodos}/>
            <Route path="/tools/schema" component={AsyncJsonSchema}/>

            <Route path={'/test-progress'} component={TestProgress}/>

            <Route path={'/minhlam/play'} component={Play}/>
            <Route path={'/minhlam/award'} component={Award}/>

            <Route path="*" component={Async404}/>
        </Switch>
    </HashRouter>
);