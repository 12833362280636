import React, {Component} from 'react'
import {Breadcrumb, Layout, Menu} from 'antd';
import SiteFooter from "../pages/components/SiteFooter";
import {Link} from "react-router-dom";
import {CheckSquareOutlined, ProfileOutlined, ToolOutlined} from '@ant-design/icons';
import ChangeLog from "../pages/changelog/ChangeLog";

const {Header, Content, Footer, Sider} = Layout;
const SubMenu = Menu.SubMenu;

class MinhLamLayout extends Component {
    state = {
        collapsed: false,
    };

    onCollapse = (collapsed) => {
        this.setState({collapsed});
    };

    componentDidMount() {
        if (this.props.title) {
            document.title = this.props.title
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.title !== this.props.title) {
            this.forceUpdate()
        }
    }

    render() {
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Sider
                    breakpoint="lg"
                    collapsedWidth="40"
                    // collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                    // trigger={null}
                >
                    <div className="logo"><Link to={'/'}><img
                        src={'https://gobiz.vn/wp-content/themes/gobiz/fav/favicon-32x32.png'} alt={'Olympus'}/>
                    </Link></div>
                    <div>
                    </div>
                </Sider>
                <Layout>
                    <Header style={{background: '#fff', padding: 0}}>
                        {this.props.header}
                    </Header>
                    <Content style={{margin: '0 16px', position: 'relative'}}>
                        <Breadcrumb style={{margin: '16px 0'}}>
                        </Breadcrumb>
                        <div style={{padding: 24, background: '#fff', minHeight: 360}}>
                            {this.props.children}
                        </div>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>

                    </Footer>
                </Layout>
            </Layout>
        );
    }
}

export default MinhLamLayout;
