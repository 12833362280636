import TaigaClient from "../TaigaClient";
import TaigaServices from "./TaigaServices";
import {LocalStore} from "../../utils/LocalStore";

export default class OlympusServices {
    baseUrl = 'https://olympus.gobizdev.com/v2/';
    // baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3001/' : 'https://olympus.gobizdev.com/v2/';
    token = '';

    setToken(token) {
        this.token = token
    }

    constructor(accessToken) {
        this.token = accessToken
    }

    buildUrl(url) {
        if (url.indexOf('http') >= 0) {
            return url
        }
        return this.baseUrl + url;
    }

    getTeams() {
        return {
            body: [
                {
                    id: 1,
                    code: 'VINASAT',
                    name: 'VINASAT',
                    projects: null,
                    filters: '10016'
                },
                {
                    id: 2,
                    code: 'Na',
                    name: 'Na Tra',
                    projects: ['KN', 'QLS'],
                    filters: '10108'
                },
                {
                    id: 3,
                    code: 'VX',
                    name: 'Greenbay',
                    projects: null,
                    filters: '10106'
                },
                {
                    id: 4,
                    code: 'HV',
                    name: 'Hảo Vọng',
                    projects: null,
                    filters: '10239'
                },
                {
                    id: 5,
                    code: 'NMV',
                    name: 'Ngưu Ma Vương',
                    projects: ['QLQ'],
                    filters: '10107'
                },
                {
                    id: 6,
                    code: 'DIA',
                    name: 'Auto Test',
                    filters: '10153'
                },
                {
                    id: 7,
                    code: 'DBT',
                    name: 'Bàn tơ',
                    filters: '10159'
                }
            ]
        };

    }

    getSprints(projectIds, from, to) {
        const projects = projectIds.map(id => `projects=${id}`).join('&');
        return TaigaClient.requestPromise({
            endpoint: this.buildUrl(`taiga/milestones?${projects}&from=${from}&to=${to}`),
            method: 'GET',
        }, this.token, TaigaServices.handleResponse)
    }

    postReview({sprintId, teamId, teamCode, sprintStart, sprintEnd, remarks, troubles, progress, summary}) {
        return TaigaClient.requestPromise({
            endpoint: this.buildUrl(`sprint_reviews`),
            method: 'POST',
            body: {sprintId, teamId, teamCode, sprintStart, sprintEnd, remarks, troubles, progress, summary}
        }, this.token, TaigaServices.handleResponse);
    }

    getReviewsByTeamId(teamId) {
        let filter = {
            where: {teamId: teamId},
            order: 'sprint_start DESC',
            limit: 50
        };
        return TaigaClient.requestPromise({
            endpoint: this.buildUrl(`sprint_reviews?filter=${JSON.stringify(filter)}`)
        }, this.token, TaigaServices.handleResponse)
    }

    postComments({objectType, objectId, objectKey, content, metadata}) {
        let profile = LocalStore.getInstance().read('taiga_session');

        return TaigaClient.requestPromise({
            endpoint: this.buildUrl(`comments`),
            body: {
                "objectType": objectType,
                "objectId": objectId,
                "objectKey": objectKey,
                "authorId": profile.id,
                "content": content,
                "metadata": metadata
            },
            method: 'POST'
        }, this.token, TaigaServices.handleResponse)
    }

    getComments(objectType, objectId, objectKey) {
        let filter = {
            where: {objectType: objectType, objectId: objectId, objectKey: objectKey},
            order: 'timestamp ASC',
            limit: 50
        };
        return TaigaClient.requestPromise({
            endpoint: this.buildUrl(`comments?filter=${JSON.stringify(filter)}`)
        }, this.token, TaigaServices.handleResponse)
    }

    static getProgress() {
        return TaigaClient.requestPromise({
            endpoint: 'https://olympus.gobizdev.com/api/Progresses?filter[order]=start_time%20DESC'
        })
    }
}