import React from 'react';
import MinhLamLayout from "../../layouts/MinhLamLayout";
import {Col, Row, Table, Button} from "antd";
import Olymp from "../../services/OlympusClient";
import { YoutubeFilled, StopFilled } from '@ant-design/icons';
import {LocalStore} from "../../utils/LocalStore";

const columns = [
    {
        title: 'Lý do',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Người thưởng',
        dataIndex: 'awardBy',
        key: 'awardBy',
        render: (text, row) => {
            let display = text;
            switch (text) {
                case 'dad':
                    display = 'Ba';
                    break;
                case 'mom':
                    display = 'Mẹ';
                    break;
            }

            return display;
        }
    },
    {
        title: 'Thời điểm',
        dataIndex: 'awardTime',
        key: 'awardTime',
        render: text => {
            // format dạng HH:mm:ss dd/MM/yyyy
            return new Date(text).toLocaleString('vi-VN');
        }
    },
    {
        title: "Thời gian",
        dataIndex: 'score',
        key: 'score',
        render: text => {
           return Play.renderTime(text)
        }
    }
];

class Play extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            score: null,
            playing: false
        }
    }

    componentDidMount() {
        Olymp.request({
            endpoint: '/api/Configs/current',
            method: 'GET',
        }, (err, result) => {
            this.setState({score: result.body.currentScore})
        });

        const filter = {
            order: "awardTime DESC",
        };

        Olymp.request({
            endpoint: '/api/Medals',
            queryString: {filter: filter},
            method: 'GET',
        }, (err, result) => {
            console.info(result);
            this.setState({data: result.body.list})
        });

        let startedAt = LocalStore.getInstance().read('play_start');

        if (startedAt) {
            this.setState({playing: true, timeElapsed: ((new Date() - new Date(startedAt)) / 1000)});
        }

        this.intervalId = setInterval(() => {
            if (this.state.playing && startedAt) {
                this.setState({timeElapsed: ((new Date() - new Date(startedAt)) / 1000)});
            }
            else {
                this.setState({timeElapsed: 0})
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    static renderTime(timeLeft) {
        // làm tròn số giây
        timeLeft = Math.floor(timeLeft);

        // check if timeLeft is negative
        const isNegative = timeLeft < 0;
        if (isNegative) {
            timeLeft = Math.abs(timeLeft);
        }

        // generate string to display timeLeft (seconds) in format HH:mm:ss
        // add leading zero if needed
        let hours = Math.floor(timeLeft / 3600);
        let minutes = Math.floor((timeLeft % 3600) / 60);
        let seconds = timeLeft % 60;
        let timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        // add negative sign if timeLeft was negative
        return isNegative ? `-${timeString}` : timeString;
    }

    /**
     * get remaining time by subtracting current score with time elapsed
     */
    getTimeRemain() {
        // Chỉ bắt đầu trừ nếu timeElapsed đã quá 300s
        if (this.state.timeElapsed < 300) {
            return this.state.score
        }

        return this.state.score - this.state.timeElapsed + 300;
    }

    /**
     * Start the clock by setting playing to true and saving the start time to local storage
     */
    startClock() {
        this.setState({playing: true});
        LocalStore.getInstance().save('play_start', new Date());
    }

    stopClock() {
        let timeElapsed = this.state.timeElapsed;
        this.setState({playing: false, timeElapsed: 0});
        LocalStore.getInstance().save('play_start', null);

        if (timeElapsed > 300) {
            // create a Medals record with time elapsed
            Olymp.request({
                endpoint: '/api/Medals',
                method: 'POST',
                body: {
                    code: 'playing',
                    name: "Chơi game/Xem youtube",
                    awardTime: new Date(),
                    awardBy: "Minh Lâm",
                    score: Math.round(-timeElapsed)
                }
            })
        }
    }

    render() {
        return <MinhLamLayout>
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        {this.state.score !== null ? <div style={{textAlign: "center"}}>
                            <h2 style={{fontSize: "24px", textAlign: "center"}}>Thời gian còn lại:</h2>
                            <div style={{
                                fontSize: "36px",
                                textAlign: "center"
                            }}>{Play.renderTime(this.getTimeRemain())}</div>
                                {
                                    // if playing is false then show Start button, else show time elapsed
                                    !this.state.playing ? <Button type={"primary"} icon={<YoutubeFilled />} size={"large"} onClick={() => this.startClock()}>Bắt đầu</Button> :
                                        <div style={{fontSize: "24px", textAlign: "center"}}>
                                            Đã chơi: {Play.renderTime((new Date() - new Date(LocalStore.getInstance().read('play_start'))) / 1000)}
                                            <br/>
                                            <Button type="primary" icon={<StopFilled />} size={"large"} onClick={() => this.stopClock()}>Stop</Button>
                                        </div>
                                }
                        </div> : null}
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Table rowKey={"id"}
                               columns={columns}
                               dataSource={this.state.data}
                               pagination={{hideOnSinglePage: true, pageSize: 50}}/>
                    </Col>
                </Row>
            </div>
        </MinhLamLayout>
    }
}

export default Play;