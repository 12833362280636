import React from 'react';
import MinhLamLayout from "../../layouts/MinhLamLayout";
import {Button, Row, Col, Select, message, Modal, Input} from "antd";
import Olymp from "../../services/OlympusClient";

const awards = [
    {
        code: "wake_up_early",
        name: "Lâm dậy trong vòng 15 phút",
        point: 300
    },
    {
        code: "brush_teeth",
        name: "Đánh răng buổi sáng",
        point: 420
    },
    {
        name: "Nói chuyện với 1 người bạn",
        point: 120,
        code: "talk_with_friend"
    },
    {
        name: "Chào một người",
        point: 30,
        code: "say_hello"
    },
    {
        name: "Giúp đỡ một người",
        point: 300,
        code: "help_someone"
    },
    {
        name: "Ăn tối với cả nhà",
        point: 150,
        code: "dinner_with_family"
    },
    {
        name: "Học bài tập trung",
        point: 600,
        code: "study_hard"
    },
    {
        name: "Viết bài cẩn thận",
        point: 300,
        code: "write_carefully"
    },
    {
        name: "Chơi với em",
        point: 300,
        code: "play_with_sister"
    },
    {
        name: "Tắm, gội đầu",
        point: 300,
        code: "shower"
    },
    {
        name: "Đi ngủ trước 9h30",
        point: 600,
        code: "sleep_early"
    },
    {
        name: "Đi ngủ trước 10h",
        point: 300,
        code: "sleep_early_10"
    },
    {
        name: "Chạy máy đi bộ",
        point: 300,
        code: "run_treadmill"
    },
    {
        name: "Đánh piano ở nhà 5 lần",
        point: 300,
        code: "play_piano"
    },
    {
        name: "Được giải thưởng",
        point: 1800,
        code: "award"
    },
    {
        name: "Được giải khen ngợi",
        point: 1200,
        code: "compliment"
    },
    {
        name: "Làm thêm thử thách của ba",
        point: 600,
        code: "dad_challenge"
    }
];

class Award extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialAwardVisible: false,
            specialAwardReason: '',
            specialAwardPoints: 0,
            awardBy: 'dad',
            score: 0
        };
    }

    award = (award) => {
        Olymp.request({
            endpoint: '/api/Medals',
            method: 'POST',
            body: {
                code: award.code,
                name: award.name,
                awardTime: new Date(),
                awardBy: this.state.awardBy,
                score: award.point
            }
        }, (err, res) => {
            if (!err) {
                message.success(`Đã thưởng ${award.name} thành công`);
            }
            this.loadTime();
        });
    };

    functionSelected = (value) => {
        this.setState({awardBy: value});
    };

    componentDidMount() {
        this.loadTime();
    }

    loadTime() {
        Olymp.request({
            endpoint: '/api/Configs/current',
            method: 'GET',
        }, (err, result) => {
            this.setState({score: result.body.currentScore});
        });
    }

    renderTime(timeLeft) {
        timeLeft = Math.floor(timeLeft);
        const isNegative = timeLeft < 0;
        if (isNegative) {
            timeLeft = Math.abs(timeLeft);
        }
        let hours = Math.floor(timeLeft / 3600);
        let minutes = Math.floor((timeLeft % 3600) / 60);
        let seconds = timeLeft % 60;
        let timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return isNegative ? `-${timeString}` : timeString;
    }

    openSpecialAwardModal = () => {
        this.setState({specialAwardVisible: true});
    };

    handleSpecialAwardSubmit = () => {
        const {specialAwardReason, specialAwardPoints, awardBy} = this.state;
        Olymp.request({
            endpoint: '/api/Medals',
            method: 'POST',
            body: {
                code: 'special_award',
                name: specialAwardReason,
                awardTime: new Date(),
                awardBy: awardBy,
                score: specialAwardPoints
            }
        }, (err, res) => {
            if (!err) {
                message.success(`Đã thưởng ${specialAwardReason} thành công`);
            }
            this.setState({specialAwardVisible: false, specialAwardReason: '', specialAwardPoints: 0});
            this.loadTime();
        });
    };

    handleSpecialAwardCancel = () => {
        this.setState({specialAwardVisible: false, specialAwardReason: '', specialAwardPoints: 0});
    };

    render() {
        return <MinhLamLayout>
            <div>
                <h2 style={{fontSize: "24px", textAlign: "center"}}>Thời gian còn lại:</h2>
                <div style={{fontSize: "36px", textAlign: "center"}}>{this.renderTime(this.state.score)}</div>
                <Select style={{width: 300}} onChange={this.functionSelected} showSearch={true} placeholder={"Ai thưởng"} defaultValue={"dad"}>
                    <Select.Option value={"dad"}>Ba</Select.Option>
                    <Select.Option value={"mom"}>Mẹ</Select.Option>
                </Select>
                <div style={{marginTop: "10px"}}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                            <Button onClick={this.openSpecialAwardModal}>Thưởng đặc biệt</Button>
                        </Col>
                        {awards.map((award, index) => (
                            <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
                                <Button onClick={() => {this.award(award)}}>{award.name}</Button>
                            </Col>
                        ))}
                    </Row>
                </div>
                <Modal
                    title="Thưởng đặc biệt"
                    visible={this.state.specialAwardVisible}
                    onOk={this.handleSpecialAwardSubmit}
                    onCancel={this.handleSpecialAwardCancel}
                >
                    <Input
                        placeholder="Lý do"
                        value={this.state.specialAwardReason}
                        onChange={(e) => this.setState({specialAwardReason: e.target.value})}
                    />
                    <Input
                        placeholder="Số giây"
                        type="number"
                        value={this.state.specialAwardPoints}
                        onChange={(e) => this.setState({specialAwardPoints: e.target.value})}
                        style={{marginTop: '10px'}}
                    />
                </Modal>
            </div>
        </MinhLamLayout>;
    }
}

export default Award;